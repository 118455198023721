<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->


                    <!-- Section Detail Start -->
                    <div class="content-onglets" v-if="key == 'detail_application' && item.show">
                        <p v-html="data.ptac['application'] ?  data.ptac['application'] : '-'"></p>                            
                    </div>
                    
                    <Indication v-if="key == 'detail_indication' && item.show"/>

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />  
   
                    <!--Decision start--> 
                    <div class="content-onglets" v-else-if="key == 'detail_decision' && item.show">
                        <p v-html="data.ptac['decision'] ?  data.ptac['decision'] : '-'"></p>                            
                    </div>
                    <!--Decision end--> 
                
                    <!--Evidence in brief start-->
                    <div class="content-onglets" v-else-if="key == 'detail_evidence_in_brief' && item.show">
                        <p v-html="data.ptac['evidence_in_brief'] ?  data.ptac['evidence_in_brief'] : '-'"></p>                            
                    </div>
                    <!--Evidence in brief end-->

                    <!--Background start-->
                    <div class="content-onglets" v-else-if="key == 'detail_background' && item.show">
                        <p v-html="data.ptac['background'] ?  data.ptac['background'] : '-'"></p>                            
                    </div>
                    <!--Background end-->
   
                    <!--Target population start-->
                    <div class="content-onglets" v-else-if="key == 'detail_target_population_text' && item.show">
                        <p v-html="data.ptac['target_population_text'] ?  data.ptac['target_population_text'] : '-'"></p>                            
                    </div>
                    <!--Target population end-->

                    <!--PtacComparatorName start--> 
                    <div class="content-onglets"  v-else-if="key == 'detail_PtacComparatorName' && item.show">
                        <table class="comparater2">
                            <tbody>
                                <tr v-for="(items,keys) in data.ptac['ptac_comparator_name']" :key="'ptac_comparator_name'+keys">
                                    <td >{{ items['titleen'] }}</td>
                                </tr>                                           
                            </tbody>
                        </table>  
                    </div>
                    <!--PtacComparatorName end--> 

                    <!--Efficacy start-->
                    <div class="content-onglets" v-else-if="key == 'detail_efficacy' && item.show">
                        <p v-html="data.ptac['efficacy'] ?  data.ptac['efficacy'] : '-'"></p>                            
                    </div>
                    <!--Efficacy end-->
                    
                    <!--Safety start-->
                    <div class="content-onglets" v-else-if="key == 'detail_safety' && item.show">
                        <p v-html="data.ptac['safety'] ?  data.ptac['safety'] : '-'"></p>                            
                    </div>
                    <!--Safety end--> 
                    
                    <!--Other start-->
                    <div class="content-onglets" v-else-if="key == 'detail_other' && item.show">
                        <p v-html="data.ptac['other'] ?  data.ptac['other'] : '-'"></p>                            
                    </div>
                    <!--Other end-->

                    <!--Other start-->
                    <div class="content-onglets" v-else-if="key == 'detail_final_considerations' && item.show">
                        <p v-html="data.ptac['final_considerations'] ?  data.ptac['final_considerations'] : '-'"></p>                            
                    </div>
                    <!--Other end-->

                    <div class="content-onglets"  v-else-if="key == 'detail_eligible_people_new_zealand' && item.show">
                        <table class="comparater2">
                            <tbody>
                                <tr>
                                    <td >{{$t('ptac.eligible_people_new_zealand')}}</td>
                                    <td width="5%" class="text-center">                                        
                                        <div class="yes-icon" v-if="data.ptac['check_eligible_people_new_zealand']"></div>
                                    </td>
                                </tr>                            
                                <tr>
                                    <td >{{$t('ptac.particular_health')}}</td> 
                                    <td class="text-center">
                                        <div class="yes-icon" v-if="data.ptac['check_particular_health']"></div>
                                    </td>
                                </tr>                            
                                <tr>
                                    <td >{{$t('ptac.availability_and_suitability')}}</td>                                    
                                    <td class="text-center">
                                        <div class="yes-icon" v-if="data.ptac['check_availability_and_suitability']"></div>
                                    </td>
                                </tr>                            
                                <tr>
                                    <td>{{$t('ptac.clinical_benefits')}}</td>                                
                                    <td class="text-center">
                                        <div class="yes-icon" v-if="data.ptac['check_clinical_benefits']"></div>
                                    </td>
                                </tr>                            
                                <tr>
                                    <td>{{$t('ptac.cost_effectiveness')}}</td>                                    
                                    <td class="text-center">
                                        <div class="yes-icon" v-if="data.ptac['check_cost_effectiveness']"></div>
                                    </td>
                                </tr>                            
                                <tr>
                                    <td>{{$t('ptac.budgetary_impact')}}</td>                                    
                                    <td class="text-center">
                                        <div class="yes-icon" v-if="data.ptac['check_budgetary_impact']"></div>
                                    </td>
                                </tr>                            
                                <tr>
                                    <td>{{$t('ptac.direct_cost')}}</td>                                      
                                    <td class="text-center">
                                        <div class="yes-icon" v-if="data.ptac['check_direct_cost']"></div>
                                    </td>
                                </tr>                            
                                <tr>
                                    <td>{{$t('ptac.government_priorities')}}</td>                                    
                                <td class="text-center">
                                        <div class="yes-icon" v-if="data.ptac['check_government_priorities']"></div>
                                    </td>
                                </tr>                            
                                <tr>
                                    <td>{{$t('ptac.criteria_pharmac')}}</td>                                    
                                    <td class="text-center">
                                        <div class="yes-icon" v-if="data.ptac['check_criteria_pharmac']"></div>
                                    </td>
                                </tr>   
                            </tbody>
                        </table>   
                    </div>

                    <!--comments start-->
                    <div class="content-onglets" v-else-if="key == 'detail_comments' && item.show">
                        <p v-html="data.ptac['comments'] ? data.ptac['comments'] : '-'"></p>                            
                    </div>
                    <!--comments end-->

                    <!--Discussion start-->
                    <div class="content-onglets" v-else-if="key == 'detail_discussion' && item.show">
                        <p v-html="data.ptac['discussion'] ? data.ptac['discussion'] : ''"></p>                            
                    </div>
                    <!--Discussion end-->

                    <!--MA spec start-->
                    <div class="content-onglets" v-else-if="key == 'detail_MaSpac' && item.show">
                        <table class="comparater2">
                            <tr>
                                <td>{{$t('TRS_AMM')}}</td>
                                <td>{{$t('ceesp_type_amm_' +data.ptac['ptac_type_amm'])}}</td>
                            </tr>
                        </table>
                    </div>
                    <!--MA spec end-->

                    <!---@todo detail_EssaisClinique  -->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>

                    <!--PrixNewZealand  -->
                    <div class="content-onglets"  v-else-if="key == 'detail_PrixNewZealand' && item.show">                        
                        <table class="comparater2">
                            <thead>
                                <tr>
                                    <th width="14%" >{{$t('ptac.name')}}</th>
                                    <th width="14%">{{$t('ptac.dci')}}</th>
                                    <th width="14%">{{$t('ptac.dosage')}}</th>
                                    <th width="14%">{{$t('ptac.units')}}</th>
                                    <th width="14%">{{$t('ptac.subsidy')}}</th>
                                    <th width="30%" colspan="2">{{$t('ptac.classification')}}</th>
                                </tr>
                            </thead>
                            <tbody>{{data.ptac.data_links}}    
                                <template v-for="(items,keys) in data.data_links['PrixNewZealandGroup']">                                                                                            
                                    <tr v-if="items['prix_new_zealand']" :key="'prix_new_zealand'+keys">
                                        <td>{{ check_empty(items['prix_new_zealand']['name']) }}</td>
                                        <td>{{ check_empty(items['prix_new_zealand']['dci']) }}</td>
                                        <td>{{ check_empty(items['prix_new_zealand']['dosage']) }}</td>
                                        <td>{{ check_empty(items['prix_new_zealand']['units']) }}</td>
                                        <td>{{ check_empty(items['prix_new_zealand']['subsidy']) }}</td>
                                        <td width="15%">{{ check_empty(items['prix_new_zealand']['classification']) }}</td>
                                        <td width="15%">{{ check_empty(items['prix_new_zealand']['pharma_code']) }}</td>                                        
                                    </tr>                                        
                                </template>                            
                            </tbody>
                        </table>
                    </div>

                    <!-- 'sub_detail/evaluation_economic.ctp'  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.ptac.evaluation_economic" />

                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />                       
                    
                    <!-- key_documents -->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                    <!-- Section Detail End -->

                </div>
            </template>

           <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import TreatmentLine from '../treatment_line.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue'
import Indication from '../Indication.vue'
import { check_empty, check_detail_conclusion, key_documents } from '../../../utils'

export default {
    name: 'ptac',
    components : {
        LeftSection,
        TreatmentLine,
        EvaluationEconomic,
        EconomicEvaluation,      
        Conclusion,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_application : {'title' : 'ptac.application', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_indication : {'title' : 'Indication under review', 'show' : true, 'enable' : false, 'orange_text':false},
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_Conclusion :  {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_decision : {'title' : 'ptac.decision', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_evidence_in_brief : {'title' : 'evidence_in_brief', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_background : {'title' : 'Background', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_target_population_text :{'title' : 'Target population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_PtacComparatorName :{'title' : 'ptac.Comparator', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_efficacy :{'title' : 'Efficacy', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_safety :{'title' : 'Safety', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_other :{'title' : 'ptac.Other', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_final_considerations :{'title' : 'Final Considerations', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_eligible_people_new_zealand :{'title' : 'ptac.decision_criteria', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_comments :{'title' : 'ptac.comments', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_discussion :{'title' : 'ptac.discussion', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_MaSpac :{'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},                                
                detail_clinical_trials : {'title':'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true }, 
                detail_PrixNewZealand :{'title' : 'ptac.PrixNewZealand', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_EvaluationEconomic :{'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_EconomicEvaluation :{'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false }, 
            }
            
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if (this.data && this.data.ptac){
            
            if(this.data.ptac['application']){
                this.list_check_box['detail_application'].enable = true
            }
            if(this.data['indication_en']) {
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration){
                this.list_check_box['treatment_line'].enable = true
            } 

            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) { 
                this.list_check_box['detail_Conclusion'].enable = true
            }

            if (this.data.ptac['decision']) {
                this.list_check_box['detail_decision'].enable = true
            }
            if (this.data.ptac['evidence_in_brief']) {
                this.list_check_box['detail_evidence_in_brief'].enable = true
            }
            if (this.data.ptac['background']) {
                this.list_check_box['detail_background'].enable = true
            }
            if (this.data.ptac['target_population_text']) {
                this.list_check_box['detail_target_population_text'].enable = true
            }
        //     // if (this.data.ptac['ptac_comparator_name'].length > 0) {
        //         this.list_check_box['detail_PtacComparatorName'].enable = true
        //     // }
            if (this.data.ptac['efficacy']){
                this.list_check_box['detail_efficacy'].enable = true
            }
            if (this.data.ptac['safety']) {
                this.list_check_box['detail_safety'].enable = true
            }
            if (this.data.ptac['other']) {
                this.list_check_box['detail_other'].enable = true
            }
            if (this.data.ptac['final_considerations']){
                this.list_check_box['detail_final_considerations'].enable = true
            }
            if (this.data.ptac['eligible_people_new_zealand'] || 
                this.data.ptac['particular_health'] ||
                this.data.ptac['availability_and_suitability'] ||
                this.data.ptac['clinical_benefits'] ||
                this.data.ptac['cost_effectiveness'] ||
                this.data.ptac['budgetary_impact'] ||
                this.data.ptac['direct_cost'] ||
                this.data.ptac['government_priorities'] ||
                this.data.ptac['criteria_pharmac'] ||
                this.data.ptac['check_eligible_people_new_zealand'] != 0 ||
                this.data.ptac['check_particular_health'] != 0 ||
                this.data.ptac['check_availability_and_suitability'] != 0 ||
                this.data.ptac['check_clinical_benefits'] != 0 ||
                this.data.ptac['check_cost_effectiveness'] != 0 ||
                this.data.ptac['check_budgetary_impact'] != 0 ||
                this.data.ptac['check_direct_cost'] != 0 ||
                this.data.ptac['check_government_priorities'] != 0 ||
                this.data.ptac['check_criteria_pharmac'] != 0 
            ) {
                this.list_check_box['detail_eligible_people_new_zealand'].enable = true
            }
            if (this.data.ptac['comments']){
                this.list_check_box['detail_comments'].enable = true
            }
            if (this.data.ptac['discussion']){
                this.list_check_box['detail_discussion'].enable = true                    
            }
            if (this.data.ptac['ptac_type_amm']) {
                this.list_check_box['detail_MaSpac'].enable = true  
            }              
            // @todo
            // if(this.data.data_links.link_evaluation_essais_clinique && this.data.link_evaluation_essais_clinique.length > 0) { 
            //     this.list_check_box['detail_EssaisClinique'].enable = true
            // }    
            if(this.data.essais_clinique &&  this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true                 
            }                
            if (this.data.data_links && this.data.data_links['PrixNewZealandGroup'] && this.data.data_links['PrixNewZealandGroup'].length > 0){
                this.list_check_box['detail_PrixNewZealand'].enable = true
            }                
            if(this.data.ptac.evaluation_economic.length > 0) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }                
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false                    
            }  
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            } 

        }
 
    },
    methods : {
        check_empty,
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }        
			
    },
}

</script>

<style>

</style>